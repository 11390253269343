<template lang="pug">
	section#resultados
		.wrapper
			.results(v-for="modulo, key in results", v-if="modulo.length")
				h2 Resultados para "{{ queried }}" em {{ getModuleName(key) }}
				ul.items
					li(v-for="item, index in modulo").item
						.container
							.title
								h3 {{ item.title }}
							.bloco
								.content
									component(:is="content.component", :contents="content.contents", v-for="content, index in mappedComponents(item)", :key="`${content.type}-${index}`")
								router-link(:to="{ name: getModuleRoute(key), params: getItemParams(item)}").squareButton Clique aqui para acessar
			Spinner(v-if="$store.state.search.loading")
			.noResults(v-if="!$store.state.search.results.length")
				h2 Não foram encontrados resultados para "{{ queried }}"
</template>

<script>
import Spinner from '@components/Spinner/Spinner'
import { textComponents, textDictionary } from '@/mixins/component'

const DICTIONARY_NAME = {
	pages: 'Páginas',
	news: 'Notícias'
}

const DICTIONARY_ROUTE = {
	pages: 'Pagina',
	news: 'Noticia' 
}

export default {
    name: "section-resultados",
	components: {
		...textComponents,
		Spinner,
	},
	computed: {
		results() {
			return this.$store.getters['search/results']
		},
		queried() {
			return this.$route.query.q
		}
	},
	methods: {
		getModuleName(key) {
			return DICTIONARY_NAME[key] || ''
		},
		getModuleRoute(key) {
			return DICTIONARY_ROUTE[key] || 'Pagina'
		},
		getItemParams(item) {
			if (item.type == 'news')
				return {
					locale: this.$store.state.locale,
					slug: item.slug
				}
			return {
				locale: this.$store.state.locale,
				category: item.category,
				subcategory: item.subCategory,
				slug: item.slug
			}
		},
		mappedComponents(item) {
			const regex = new RegExp(`(${this.$route.query.q})`, `gi`)
			return item.content.filter(item => item.type == 'paragraph').map(item => {
				const content = item.content.replace(regex, `<strong>$1</strong>`)
				return { component: this.$options.components[textDictionary[item.type]], contents: { ...item, content }, type: item.type }
			})
		}
	}
}
</script>

<style lang="stylus" scoped src="./Resultados.styl"></style>
