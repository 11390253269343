<template lang="pug">
	main#busca
		Banner(:contents="banner")
		Resultados
</template>

<script>
import Banner from '@sections/Pagina/Banner/Banner'
import Resultados from '@sections/Busca/Resultados/Resultados'

export default {
	name: "view-busca",
	components: {
		Banner,
		Resultados,
	},
	metaInfo() {
		return {
			title: 'Resultados da Busca',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	data() {
		return {
			banner: {
				banner: {
					alt: `Resultados da Busca`,
					src: require(`@images/paginas/banner-pagina.jpg`),
				},
				title: `Resultados da Busca`
			},
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$store.dispatch('search/getResults', to.query.q)
		})
	},
	beforeRouteUpdate(to, from, next) {
		this.$store.dispatch('search/getResults', to.query.q)
		next()
	}
}
</script>

<style lang="stylus" scoped src="./Busca.styl"></style>
